
  import { Component, Watch } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { Form } from '@/entities/public/Resource/metadata'
  import {
    fixAmountFormatted,
    fixPrice,
    isValidNumber,
    parseToNumber,
    stringifySafe,
    toCamelCase,
    updateNestedObject,
  } from '@/utils/general'
  import { EnablementTask } from '@/entities/purchase'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import GCostField from '@/components/core/input/GCostField.vue'
  import { EnablementTaskView } from '@/components/forms/view/EnablementTaskView'
  import _ from 'lodash'
  import { Person, PersonAccount } from '@/entities/persons'
  import { Debounce } from '@/utils/decorators'
  import { FinancialAccount, Payment, PaymentRecipient, PaymentType } from '@/entities/finance'
  import { plainToInstance } from 'class-transformer'

@Component({
  components: { GCostField, GRadioButton, GFiles, LinkedPerson, BaseForm },
  methods: { fixAmountFormatted, fixPrice, isValidNumber },
  computed: {},
})
  export default class EnablementExpenseForm extends EnablementTaskView {
  declare $refs: {
    form: HTMLFormElement
    fieldBeneficiary: LinkedPerson
  };

  title = 'Gasto habilitación'
  processEnablementTaskId
  person: Person = null
  enablementTask: EnablementTask | null = null
  showDetail = false
  accounts: PersonAccount[] = []
  paymentType: PaymentType[] = []

  path = ''
  errorAccount = ''
  errorRut = ''
  errorMail = ''

  formData = {
    paymentType: null,
    expenseType: null,
    concept: null,
    beneficiary: null,
    account: null,
    amount: null,
  }

  fields = {
    accounts: {
      properties: {
        label: 'Cuenta de cargo',
        itemText: 'bankAccount',
        itemValue: 'id',
        returnObject: true,
        required: true,
        rules: this.fieldRequired,
      },
      items: [],
    },
    beneficiary: {
      properties: {
        properties: {
          label: 'Beneficiario',
          rules: this.fieldRequired,
          required: true,
          itemText: 'formInfo',
          itemValue: 'id',
          clearable: true,
          returnObject: true,
        },
      },
      items: [],
    },
    expenseType: {
      properties: {
        label: 'Tipo de gasto',
        rules: this.fieldRequired,
        required: true,
        itemText: 'name',
        itemValue: 'id',
        clearable: true,
        returnObject: true,
      },
      items: [],
    },
    expenses: {
      properties: {
        label: 'Concepto de gasto',
        rules: this.fieldRequired,
        required: true,
        itemText: 'description',
        itemValue: 'id',
        clearable: true,
        returnObject: true,
      },
      items: [],
    },
  }

  metadata = {}
  metadataCollection = {}

  get bindBeneficiary () {
    const { fields: { beneficiary } } = this

    if (!beneficiary) return null
    return { ...beneficiary.properties, items: beneficiary.items }
  }

  async mounted () {
    this.loadingForm = true

    await this.setMetadata()
    const { id, title, metadataCollection } = this

    if (isValidNumber(id)) {
      await this.getEnablementTaskInfo(id)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadataCollection, title, Boolean(id))
    }

    await this.setTheBackup()
    this.displayDetail()
    this.loadingForm = false
  }

  async setTheBackup () {
    const { backup } = this
    if (!backup?.stockDocumentationForm) {
      return this.close()
    }

    const isStockDocumentationForm = 'stockDocumentationForm' in backup && _.cloneDeep(backup.stockDocumentationForm)
    const isBeneficiary = 'beneficiary' in backup && backup.beneficiary
    const isEnablementTaskForm = 'enablementTaskForm' in backup && backup.enablementTaskForm

    if (isStockDocumentationForm) {
      this.formData.expenseType = this.fields.expenseType.items.find(item => item.name === 'documentation')
      await this.setBeneficiary(isStockDocumentationForm)
      this.path = isStockDocumentationForm.path
    }

    if (isEnablementTaskForm) {
      this.formData = { ...isEnablementTaskForm }
      this.fields.beneficiary.items = [isBeneficiary]
      this.formData.beneficiary = isBeneficiary
    }
  }

  async setCirculationPermit (data, total = 0) {
    const cities = await this.fetchData({
      query: { name: 'find', model: 'City' },
      filter: { name: { _eq: data?.selection?.name } },
    })
    this.fields.beneficiary.properties.properties.label = 'Municipalidad de'
    this.fields.beneficiary.properties.properties.itemText = 'name'
    this.fields.beneficiary.properties.properties.itemValue = 'id'
    this.fields.beneficiary.properties.properties.rules = this.fieldRequired
    this.fields.beneficiary.items = cities
    this.formData.amount = parseToNumber(data?.cost) - total
    this.formData.beneficiary = cities.find(city => city.id === data?.selection?.id)

    this.person = (await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: {
        company_name: { _ilike: `Municipalidad de %${data?.selection?.name}%` },
      },
    }))[0]
  }

  async setTechnicalReview (data, total = 0) {
    const plants = await this.fetchData({
      query: {
        name: 'find',
        model: 'Person',
        order: {
          alias: 'asc',
        },
      },
      filter: { company_type: { name: { _eq: `technical_review_plant` } } },
      limit: 100,
      force: true,
    })
    this.fields.beneficiary.properties.properties.label = 'Planta de revisión técnica'
    this.fields.beneficiary.properties.properties.itemText = 'alias'
    this.fields.beneficiary.properties.properties.itemValue = 'id'
    this.fields.beneficiary.properties.properties.rules = this.fieldRequired
    this.fields.beneficiary.items = plants
    this.formData.amount = parseToNumber(data?.cost) - total
    this.formData.beneficiary = plants.find(plant => plant.id === data?.selection?.id)
  }

  async setSoap (data, total = 0) {
    const companyType = await this.fetchData({
      query: { name: 'find', model: 'CompanyType' },
      filter: { name: { _eq: 'insurance_carrier' } },
      force: true,
    })

    this.fields.beneficiary.items = companyType?.[0]?.persons
    this.fields.beneficiary.properties.properties.label = 'Aseguradora'
    this.fields.beneficiary.properties.properties.itemText = 'alias'
    this.fields.beneficiary.properties.properties.itemValue = 'id'
    this.fields.beneficiary.properties.properties.rules = this.fieldRequired
    this.formData.amount = parseToNumber(data?.cost) - total
    this.formData.beneficiary = companyType?.[0]?.persons.find(person => person.id === data?.selection?.id)
  }

  async setBeneficiary (documentationForm) {
    const { path } = documentationForm
    if (!path) return

    const pathFormatted = toCamelCase(path)

    if (path === 'circulation_permit') {
      await this.setCirculationPermit(documentationForm[pathFormatted], documentationForm?.lastOrder?.totalPayments)
    } else if (path === 'technical_review') {
      await this.setTechnicalReview(documentationForm[pathFormatted], documentationForm?.lastOrder?.totalPayments)
    } else if (path === 'soap') {
      await this.setSoap(documentationForm[pathFormatted], documentationForm?.lastOrder?.totalPayments)
    }
    console.log('aqui')
    const financialAccount = await this.fetchData({
      query: { name: 'find', model: 'FinancialAccount' },
      filter: {
        _and: [
          { category: { name: { _eq: 'spent' } } },
          { active: { _eq: true } },
          {
            person_account: {
              _and: [
                { active: { _eq: true } },
                { bank_data: { account_type: { name: { _in: ['debit_card', 'credit_card'] } } } },
              ],
            },
          },
        ],
      },
      force: true,
    })

    const types = [...new Set(financialAccount.map(account => account.personAccount.bankData.accountType.name))] as string[]

    this.paymentType = await this.fetchData({
      query: { name: 'find', model: 'PaymentType', order: { name: 'desc' } },
      filter: { name: { _in: ['cash', ...types] } },
      force: true,
    })
  }

  async setMetadata () {
    const { metadata } = this.getForm('EnablementTask', 'inspector_enablement_expense')
    this.title = 'Gasto habilitación'
    this.metadataCollection = metadata

    const process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'enablement_task' } },
    }))[0]

    this.processEnablementTaskId = process?.id

    this.fields.expenseType.items = await this.fetchData({
      query: { name: 'find', model: 'ExpenseType' },
      filter: { name: { _neq: 'auto_purchase' } },
    })
  }

  async getEnablementTaskInfo (id) {
    if (!id) return
    this.enablementTask = await this.fetchData({
      query: { name: 'fetch', model: 'EnablementTask', params: { id } },
      force: true,
    })
  }

  setProperties (fileInfo, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo[0]
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  displayDetail () {
    const { enablementTask, metadataCollection } = this

    if (!enablementTask) {
      this.showDetail = false
      return
    }

    this.metadata = {
      data: enablementTask,
      metadata: metadataCollection,
    }

    this.showDetail = Boolean(enablementTask?.id)
  }

  async send () {
    if (!this.$refs.form.validate()) {
      await this.setFieldError()
      return
    }
    this.loadingForm = true

    const recipient = plainToInstance(PaymentRecipient, {})
    recipient.person = this.person?.id ? this.person : this.formData?.beneficiary

    const payment = plainToInstance(Payment, {
      amount: this.formData?.amount,
      id_financial_account: this.formData?.account?.id,
      financialAccount: plainToInstance(FinancialAccount, { id: this.formData?.account?.id }),
      // comment: isExpenseForm?.comment,
      // paymentBackup: isExpenseForm?.file,
      type: plainToInstance(PaymentType, {
        id: this.formData?.paymentType?.id,
        name: this.formData?.paymentType?.name,
        description: this.formData?.paymentType?.description,
      }),
    })
    recipient.payments = [payment]

    this.saveBackup(recipient)
    this.loadingForm = false
    await this.close()
  }

  get change () {
    const { formData } = this

    return stringifySafe([formData])
  }

  get isCirculationPermit () {
    const { path } = this

    return path === 'circulation_permit'
  }

  get isTechnicalReview () {
    const { path } = this

    return path === 'technical_review'
  }

  get isSoap () {
    const { path } = this

    return path === 'soap'
  }

  get isDocumentation () {
    const { isCirculationPermit, isSoap, isTechnicalReview } = this

    return isSoap || isTechnicalReview || isCirculationPermit
  }

  async searchPerson ({ input }) {
    if (!input?.length || input?.length < 2 || this.backup?.stockDocumentationForm) return

    const { metadata } = this.getForm('Lead', 'lead')

    if (!metadata) return
    const { fields } = metadata as Form
    const query = updateNestedObject(fields.client.computed.queries.items.where, '_eq', this.replaceSpace(input), ['name_person_type'])

    this.fields.beneficiary.items = await this.findPersonSearch(query)
  }

  saveBackup (recipient = null) {
    const { backup, formData } = this

    if (backup) {
      backup.enablementExpenseForm = { ...formData, recipient }
      backup.beneficiary = null
      this.setBackup(backup)
    } else {
      this.setBackup({ enablementExpenseForm: { ...formData, recipient } })
    }
  }

  async goPerson () {
    const { formData } = this
    this.saveBackup()
    this.setAllowEnterprise(true)

    const idPerson = formData?.beneficiary?.id || 'created'
    await this.$router.push({
      name: 'generic-person-nested',
      params: { model: 'EnablementTask', id: idPerson },
    })
  }

  @Watch('formData.beneficiary', { deep: true })
  @Debounce(2000)
  async onBeneficiaryChange (val) {
    if (!val?.id) return

    if (val?.id && !this.isDocumentation) {
      await this.checkData(val)
      await this.checkAccount(val.id)
    }
  }

  async checkData (val) {
    if (!val?.id || this.isDocumentation) return
    const person = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: val.id } },
      force: true,
    })

    this.errorRut = !person?.uid ? 'El rut es obligatorio' : ''
    this.errorMail = !person?.email?.personal ? `El correo es obligatorio` : ''
  }

  async checkAccount (id) {
    if (!id || this.isDocumentation) return
    const accounts = await this.fetchData({
      query: { name: 'find', model: 'PersonAccount' },
      filter: { _and: [{ id_person: { _eq: id } }, { active: { _eq: true } }] },
      force: true,
    })

    if (!accounts.length) {
      this.errorAccount = 'No se encontraron cuentas bancarias para este beneficiario por favor agregue una'
    } else {
      this.errorAccount = ''
    }
    this.accounts = accounts
  }

  @Watch('formData.expenseType', { deep: true, immediate: true })
  async fillExpenses () {
    const { formData, backup } = this
    const { expenseType } = formData
    if (!expenseType || !backup) return

    this.fields.expenses.items = await this.fetchData({
      query: { name: 'find', model: 'Expense' },
      filter: { _and: [{ id_expense_type: { _eq: expenseType.id } }, { name: { _eq: backup.path } }] },
    })
    this.formData.concept = this.fields.expenses.items[0]
  }

  @Watch('formData.paymentType', { deep: true })
  async onPaymentTypeChange (val) {
    if (!val?.name) return

    if (val?.name === 'cash') {
      this.formData.account = null
    } else {
      let accountType = null

      if (!val?.name.includes('card')) {
        accountType = ['bank_account', 'savings_account', 'view_account']
      } else if (val?.name === 'credit_card') {
        accountType = ['credit_card']
      } else {
        accountType = ['debit_card']
      }

      this.fields.accounts.items = await this.fetchData({
        query: { name: 'find', model: 'FinancialAccount' },
        filter: {
          _and: [
            { category: { name: { _eq: 'spent' } } },
            {
              person_account: {
                _and: [
                  { active: { _eq: true } },
                  { bank_data: { account_type: { name: { _in: accountType } } } },
                ],
              },
            },
            { active: { _eq: true } },
          ],
        },
      })
    }
  }
  }
